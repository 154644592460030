import { useHttp } from '@/middleware/httpMiddleware';

async function checkAdmin (period) {
  const data = await useHttp(
    '/admin/validate',
    'GET',
    null
  );
  return data.payload;
}

async function getCustomers (q = '', filters = '', offset = 0, order, limit = 25, cred = false, baseInfoOnly = false) {
  const data = await useHttp(
    '/admin/customers',
    'GET',
    null,
    !baseInfoOnly
      ? {
          labels: JSON.stringify(filters?.labels || []),
          brands: JSON.stringify(filters?.brands || []),
          statuses: JSON.stringify(filters?.statuses || []),
          types: JSON.stringify(filters?.types || []),
          triggers: JSON.stringify(filters?.triggers || []),
          tier: filters?.tier?.length ? filters?.tier[0] : '',
          order,
          offset,
          q,
          limit,
          with_credentials: cred,
        }
      : {
          offset,
          q,
          limit,
          base_info_only: true,
        }
  );

  return data.payload;
}

async function getCustomerInfo (id) {
  const data = await useHttp(
    `/admin/customers/${id}/customer_info`,
    'GET',
    null

  );
  return data.payload;
}

async function getConfig () {
  const data = await useHttp(
    '/admin/config',
    'GET',
    null,
    {}
  );
  return data.payload;
}
async function updateConfig (update) {
  const data = await useHttp(
    '/admin/config/update',
    'POST',
    { config: JSON.stringify(update) }
  );
  return data.payload;
}

async function getReports (period) {
  const data = await useHttp(
    '/admin/reports/fetch',
    'GET',
    null,
    { period }
  );
  return data.payload;
}

async function updateCustomer (id, update) {
  const data = await useHttp(
    `/admin/customers/${id}/update`,
    'POST',
    update,
    null,
    true
  );
  return data.payload;
}

async function getTransactions () {
  const data = await useHttp(
    '/admin/transactions',
    'GET',
    null
  );
  return data.payload;
}

async function updateUserByAdmin (id, user) {
  const data = await useHttp(
    `/admin/users/${id}/update`,
    'POST',
    {
      user_object: JSON.stringify(user),
    }
  );
  return data.payload;
}

async function getPrepaidCustomers (customer) {
  const data = await useHttp(
    '/admin/prepaid_customers/get_prepaid_customers',
    'GET'
  );
  return data.payload;
}

async function createPrepaidCustomer (email, customerId, currency, paymentPlan, period) {
  const data = await useHttp(
    '/admin/prepaid_customers/create_prepaid_customer',
    'POST',
    {
      email,
      stripe_customer: customerId,
      currency,
      tier: paymentPlan,
      interval: period,
    },
    null,
    true
  );
  return data.payload;
}

async function deletePrepaidCustomer (id) {
  const data = await useHttp(
    `/admin/prepaid_customers/${id}/delete`,
    'DELETE',
    null
  );
  return data.payload;
}

async function getCustomersStripe (q = '', offset = 0) {
  const data = await useHttp(
    '/admin/stripe/customers/fetch',
    'GET',
    null,
    {
      offset,
      q,
      limit: 10,
    }
  );
  return data.payload;
}

async function setUnlimited (id) {
  const data = await useHttp(
    `/admin/space/${id}/set_unlimited`,
    'POST'
  );
  return data.payload;
}

async function setStripeRefresh (id) {
  const data = await useHttp(
    `/admin/space/${id}/stripe/refresh`,
    'POST'
  );
  return data.payload;
}

async function linkCustomerStripe (spaceId, stripeId) {
  const data = await useHttp(
    '/admin/customers/link_stripe',
    'POST',
    {
      space_id: spaceId,
      stripe_id: stripeId,
    },
    null,
    true
  );
  return data.payload;
}

async function getHealthIndex () {
  const data = await useHttp(
    '/admin/health_index/rules',
    'GET',
    null
  );
  return data.payload;
}
async function getHealthStatistics () {
  const data = await useHttp(
    '/admin/health_index/statistics',
    'GET',
    null
  );
  return data.payload;
}
async function getHealthMainStatistics () {
  const data = await useHttp(
    '/admin/health_index/statistics/main',
    'GET',
    null
  );
  return data.payload;
}

async function createHealthIndex (rule) {
  const data = await useHttp(
    '/admin/health_index/rules',
    'POST',
    rule,
    null,
    true
  );
  return data.payload;
}

async function updateHealthRules (rulesId, updateData) {
  const data = await useHttp(
    `/admin/health_index/rules/${rulesId}`,
    'PUT',
    updateData,
    null,
    true
  );

  return data.payload;
}
async function recalculateStatistics () {
  const data = await useHttp(
    '/admin/health_index/recalculate',
    'POST',
    null
  );

  return data.payload;
}

async function getHealthCred (credId) {
  const data = await useHttp(
    `/admin/credentials/${credId}/health_index_statistics`,
    'GET',
    null,
    null,
    true
  );

  return data.payload;
}

async function getPricing (brand) {
  const data = await useHttp(
    `/admin/billing/${brand}/prices`,
    'GET',
    null,
    null,
    true
  );
  return data.payload;
}

async function getPricingId (brand, type, settings) {
  const data = await useHttp(
    `/admin/billing/${brand}/${type}/prices`,
    'GET',
    null,
    settings,
    true
  );
  return data.payload;
}

async function createPricePlan (brand, type, priceId, priceName) {
  const data = await useHttp(
    `/admin/billing/${brand}/prices/${type}`,
    'POST',
    {
      stripe_price_id: priceId,
      name: priceName,
    },
    null,
    true
  );
  return data.payload;
}

async function deletePricePlan (brand, type, priceId) {
  const data = await useHttp(
    `/admin/billing/${brand}/prices/${type}/${priceId}`,
    'DELETE',
    null,
    null,
    true
  );
  return data.payload;
}

async function updatePricePlan (brand, type, priceId, priceName, prevId) {
  const data = await useHttp(
    `/admin/billing/${brand}/prices/${type}/${prevId}`,
    'PUT',
    {
      stripe_price_id: priceId,
      name: priceName,
    },
    null,
    true
  );
  return data.payload;
}
async function saveToSendTrigger (config, triggers, emails) {
  const data = await useHttp(

    `/admin/whitelabels/${config}/health_index`,
    'PUT',
    {

      triggers,
      emails,
    },
    null,
    true
  );

  return data.payload;
}

async function getDataTrigger () {
  const data = await useHttp(
    '/admin/health_index/triggers',
    'GET',
    null,
    null,
    true
  );
  return data.payload;
}

async function sendTestEmail (email, user) {
  const data = await useHttp(
    '/admin/emails/test',
    'POST',
    {
      email,
      user,
      types: [
        'weekly_performance',
      ],
    },
    {},
    true
  );
  return data.payload;
}

async function unsetCredits (space, credits) {
  const data = await useHttp(
    `/admin/customers/${space}/uset_credits`,
    'POST',
    {
      count: credits,
    },
    {},
    true
  );
  return data.payload;
}

async function updateUserSpaces (userId, newSpaces, unsetSpaces) {
  const data = await useHttp(
    `/admin/users/${userId}/update_spaces`,
    'POST',
    {
      new_spaces: newSpaces || [],
      spaces_to_unset: unsetSpaces || [],
    },
    {},
    true
  );
  return data.payload;
}

export {
  saveToSendTrigger,
  getDataTrigger,
  deletePrepaidCustomer,
  getPrepaidCustomers,
  createPrepaidCustomer,
  getCustomers,
  getConfig,
  updateConfig,
  getReports,
  checkAdmin,
  updateCustomer,
  getTransactions,
  updateUserByAdmin,
  getCustomerInfo,
  getCustomersStripe,
  linkCustomerStripe,
  getHealthIndex,
  getHealthStatistics,
  getHealthMainStatistics,
  updateHealthRules,
  createHealthIndex,
  recalculateStatistics,
  setStripeRefresh,
  setUnlimited,
  getHealthCred,
  getPricing,
  getPricingId,
  createPricePlan,
  deletePricePlan,
  updatePricePlan,
  sendTestEmail,
  unsetCredits,
  updateUserSpaces
};
